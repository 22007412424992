import * as React from 'react';
import { connectSearchBox } from 'react-instantsearch-core';
import ConnectedAutocompleteInput from './autocomplete';
class SearchBox extends React.Component {
    constructor(props) {
        super(props);
        this.placeChanged = (place) => {
            this.props.refine('');
            this.setState({ searchText: '' });
        };
        this.state = {
            searchText: props.currentRefinement,
        };
        this.submitSearch = this.submitSearch.bind(this);
        this.searchInputChanged = this.searchInputChanged.bind(this);
    }
    componentDidMount() {
        if (!this.props.currentRefinement && typeof (window) != 'undefined' && window.location.search) {
            const query = new URLSearchParams(window.location.search).get('query');
            if (query) {
                this.setState({
                    searchText: query,
                });
                this.props.refine(query);
            }
        }
    }
    render() {
        const { searchText } = this.state;
        return React.createElement("div", { className: "ais-SearchBox" },
            React.createElement("form", { className: "ais-SearchBox-form", action: "", role: "search", onSubmit: this.submitSearch },
                React.createElement(ConnectedAutocompleteInput, { type: "search", className: "ais-SearchBox-input", placeholder: "City, State, Country, Church Name...", value: searchText, google: this.props.google, loaded: this.props.loaded, onChange: this.searchInputChanged, onPlaceChanged: this.placeChanged }),
                React.createElement("button", { type: "submit", title: "Submit your search query.", className: "ais-SearchBox-submit" })));
    }
    searchInputChanged(evt) {
        evt.preventDefault();
        this.setState({
            searchText: evt.target.value,
        });
        if (this.props.searchAsYouType) {
            this.props.refine(this.state.searchText);
        }
    }
    submitSearch(evt) {
        evt.preventDefault();
        this.props.refine(this.state.searchText);
    }
}
const ConnectedSearchBox = connectSearchBox(SearchBox);
export default ConnectedSearchBox;
