import date_fns_tz_format from 'date-fns-tz/format';
import { Daynames } from './types';
export function present(value) {
    if (typeof value == 'string') {
        return value && /\S/.test(value);
    }
    return !!value;
}
export function parsePosition(aroundLatLng) {
    if (!aroundLatLng) {
        return;
    }
    const m = aroundLatLng.match(/^((?:-|\+)?[\d\.]+),\s*((?:-|\+)?[\d\.]+$)/i);
    if (!m) {
        return;
    }
    return {
        lat: parseFloat(m[1]),
        lng: parseFloat(m[2]),
    };
}
const MilesPerMeter = 0.000621371192;
export function metersToMiles(meters) {
    return meters * MilesPerMeter;
}
export function milesToMeters(miles) {
    return miles / MilesPerMeter;
}
const tooBigPlaces = [
    'country',
    'administrative_area_level_1',
    'administrative_area_level_2',
];
export function isTooBig(place) {
    if (place.types && intersects(place.types, tooBigPlaces)) {
        return true;
    }
    if (place.types && place.types.indexOf('locality')) {
        // some cities are bigger than others
        // super imprecise check, roughly equivalent to 25 miles either direction
        const span = place.geometry.viewport.toSpan();
        return span.lat() > 0.5 || span.lng() > 0.5;
    }
    return false;
}
function intersects(a, b) {
    for (const val of a) {
        if (b.indexOf(val) >= 0) {
            return true;
        }
    }
    return false;
}
export function renderAddress(address) {
    return [
        address.street1, address.city, address.state, address.zip,
    ].join(', ');
}
export function renderMeetingTime(location, translate) {
    const day = Daynames.indexOf(location.meetingDay);
    const parts = [];
    if (day != -1) {
        const meetingDay = translate(['locationMap', 'meetingDay', 'days_of_week', day], { defaultValue: location.meetingDay });
        parts.push(meetingDay);
    }
    if (location.meetingTime && location.meetingTime.length > 0) {
        parts.push(location.meetingTime);
    }
    // TODO: translate(meetingTime)
    return parts.join(' @ ');
}
/**
 * Render a short name for meeting time zone
 */
export function renderMeetingTZ(timeZone, referenceDate) {
    switch (timeZone) {
        case 'America/Chicago':
            return 'Central';
        case 'America/New_York':
            return 'Eastern';
        case 'America/Denver':
            return 'Mountain';
        case 'America/Los_Angeles':
            return 'Pacific';
    }
    if (referenceDate) {
        // we can try using utilities to get the timezone short name
        return date_fns_tz_format(new Date(referenceDate), 'zzz', { timeZone });
    }
    // else we couldn't figure it out
    return timeZone;
}
export function renderWebsite(website) {
    try {
        const url = new URL(website);
        return url.hostname;
    }
    catch (ex) {
        return website;
    }
}
