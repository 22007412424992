import * as React from 'react';
import { metersToMiles, renderAddress, renderMeetingTime, renderMeetingTZ } from '../utils';
export const DefaultListItem = ({ hit, ...rest }) => {
    if (hit.isVirtual) {
        return React.createElement(VirtualListItem, Object.assign({}, rest, { hit: hit }));
    }
    return React.createElement(InPersonListItem, Object.assign({}, rest, { hit: hit }));
};
const VirtualListItem = ({ hit, translate }) => {
    const genderKey = hit.genders.length > 1 ? 'all' : hit.genders[0];
    return (React.createElement("div", { className: "churches-list__item__content" },
        hit.trainingLocation && React.createElement(TrainingBadge, null),
        hit.has_spanish && React.createElement(SpanishBadge, null),
        React.createElement("h4", { className: "churches-list__item__content__title" },
            renderMeetingTime(hit, translate),
            " ",
            renderMeetingTZ(hit.meetingTimeZone, hit.next_occurrence && hit.next_occurrence.start_time_ts)),
        React.createElement("div", null,
            hit.address && React.createElement("span", { className: "churches-list__item__content__text" }, renderAddress(hit.address)),
            hit.genders.length > 0 && React.createElement("span", { className: "churches-list__item__content__datetime" },
                "\u00A0",
                translate(['locationMap', 'meetingGender', genderKey])),
            React.createElement("span", { className: "churches-list__item__content__account-name" }, hit.title))));
};
const InPersonListItem = ({ hit, google, currentRefinement, translate }) => {
    return (React.createElement("div", { className: "churches-list__item__content" },
        hit.trainingLocation && React.createElement(TrainingBadge, null),
        hit.has_spanish && React.createElement(SpanishBadge, null),
        React.createElement("h4", { className: "churches-list__item__content__title" }, hit.title),
        React.createElement("div", null,
            React.createElement("span", { className: "churches-list__item__content__text" }, renderAddress(hit.address)),
            React.createElement("span", { className: "churches-list__item__content__datetime" },
                "\u00A0",
                renderMeetingTime(hit, translate)),
            currentRefinement.userLocation &&
                React.createElement("span", { className: "churches-list__item__content__distance" }, calculateDistanceText({ google, currentRefinement, translate, hit })))));
};
function TrainingBadge() {
    return React.createElement("span", { className: "churches-list__item__content__utility" },
        React.createElement("i", { className: "icon material-icons" }, "star_rate"),
        "Training Location");
}
function SpanishBadge() {
    return React.createElement("span", { className: "churches-list__item__content__utility" }, "En Espa\u00F1ol");
}
/**
 * Calculates the translation key and params for the distance text in a list item.
 * Then calls into the given "Translate" function to create the text.
 *
 * Used in a ListItem to render a human readable distance.
 */
export function calculateDistanceText(props) {
    const { LatLng } = props.google.maps;
    const { computeDistanceBetween } = props.google.maps.geometry.spherical;
    const { hit, currentRefinement } = props;
    if (!currentRefinement.userLocation) {
        return '';
    }
    const { userLocation, selectedPlace } = currentRefinement;
    const from = userLocation instanceof LatLng ?
        userLocation :
        new LatLng(userLocation.lat, userLocation.lng);
    const to = new LatLng(hit._geoloc.lat, hit._geoloc.lng);
    const meters = computeDistanceBetween(from, to);
    const km = meters / 1000;
    const miles = metersToMiles(meters);
    const params = {
        miles: formatDistance(miles),
        km: formatDistance(km),
        place: undefined,
    };
    const key = ['locationMap', 'distance'];
    if (selectedPlace) {
        params.place = selectedPlace.name;
        key.push('aroundPlace');
    }
    if (miles < 1.0) {
        return props.translate([...key, 'lessThan1'], params);
    }
    return props.translate([...key, 'other'], params);
}
function formatDistance(value) {
    if (value < 100) {
        return value.toFixed(1);
    }
    return value.toFixed(0);
}
