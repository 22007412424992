import * as React from 'react';
export class TabNavItem extends React.PureComponent {
    constructor(props) {
        super(props);
        this.componentDidMount = () => {
            const { _window } = this.props;
            $(this.anchorRef.current).on('shown.bs.tab', this.onTabSelected);
            if (this.storeStateInHash()) {
                $(_window).on('hashchange', this.hashChangeHandler);
                this.hashChangeHandler();
            }
            if (this.storeStateInPath()) {
                _window.addEventListener('popstate', this.onPopStateHandler);
                this.onPopStateHandler();
            }
        };
        this.componentWillUnmount = () => {
            const { _window } = this.props;
            if (this.storeStateInHash()) {
                $(_window).on('hashchange', this.hashChangeHandler);
            }
            if (this.storeStateInPath()) {
                _window.removeEventListener('popstate', this.onPopStateHandler);
            }
        };
        this.hashChangeHandler = () => {
            const { _window } = this.props;
            if (!this.storeStateInHash()) {
                return;
            }
            const bookmark = this.props.bookmark || this.props.id;
            if (_window.location.hash && _window.location.hash == '#' + encodeURIComponent(bookmark)) {
                $(this.anchorRef.current).tab('show');
            }
        };
        this.onPopStateHandler = () => {
            const { _window } = this.props;
            if (!this.storeStateInPath()) {
                return;
            }
            const bookmark = this.props.bookmark || this.props.id;
            if (lastPathPart(_window.location.pathname) == bookmark) {
                $(this.anchorRef.current).tab('show');
            }
        };
        this.onTabSelected = (evt) => {
            const { onTabSelected, _window } = this.props;
            const bookmark = this.props.bookmark || this.props.id;
            if (this.storeStateInHash()) {
                _window.location.hash = encodeURIComponent(bookmark);
            }
            else if (this.storeStateInPath()) {
                this.updatePath(bookmark, evt.relatedTarget && $(evt.relatedTarget).data('bookmark'));
            }
            if (onTabSelected) {
                onTabSelected(this.props.id);
            }
        };
        this.updatePath = (bookmark, relatedBookmark) => {
            const { _window } = this.props;
            const currentBookmark = lastPathPart(_window.location.pathname);
            if (currentBookmark && currentBookmark == bookmark) {
                return;
            }
            let slug;
            if (relatedBookmark &&
                relatedBookmark == currentBookmark) {
                // we are navigating between two tabs
                slug = bookmark;
            }
            else {
                // we are navigating from the root into one of the tabs
                slug = [_window.location.pathname.replace(/\/+$/, ''), bookmark].join('/');
            }
            const newLocation = new URL(slug, _window.location.toString());
            if (_window.location.search) {
                newLocation.search = _window.location.search;
            }
            _window.history.pushState({ bookmark, id: this.props.id }, document.title, newLocation.toString());
        };
        this.storeStateInHash = () => {
            return this.props.storeState === true || this.props.storeState === 'hash';
        };
        this.storeStateInPath = () => {
            return this.props.storeState === 'path';
        };
        this.anchorRef = React.createRef();
    }
    render() {
        const { id, translations, active, hidden } = this.props;
        const bookmark = this.props.bookmark || this.props.id;
        const mobileHeader = translations && (translations.mobileHeader || translations.header);
        const header = translations && translations.header;
        return (React.createElement("li", { className: "nav-item", style: { display: hidden ? 'none' : undefined } },
            React.createElement("a", { ref: this.anchorRef, className: `nav-link ${active ? 'active' : ''}`, "data-toggle": "tab", role: "tab", "aria-controls": `#${id}-tab`, "aria-selected": active, id: `${id}-tab-nav`, href: `#${bookmark}`, "data-bookmark": bookmark },
                React.createElement("span", { className: "d-md-none" }, mobileHeader || id),
                React.createElement("span", { className: "d-none d-md-inline" }, header || id))));
    }
}
TabNavItem.defaultProps = {
    storeState: false,
    _window: typeof (window) != 'undefined' ? window : undefined,
};
export class TabPane extends React.PureComponent {
    render() {
        const { id, active, children } = this.props;
        return (React.createElement("div", { className: `tab-pane ${active ? 'show active' : ''}`, id: `${id}-tab`, role: "tabpanel", "aria-labelledby": `${id}-tab-nav` }, children));
    }
}
function lastPathPart(pathname) {
    return pathname.split('/').filter((p) => p.length > 0).pop();
}
