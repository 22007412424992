import { createConnector } from 'react-instantsearch-core';
import { present, isTooBig, milesToMeters, parsePosition } from '../utils';
const maxMarkersToShowInitially = 10;
const maxMapBoundMeters = milesToMeters(250);
const defaultBoundsMeters = milesToMeters(50); // 50 miles
const centerUS = { lat: 39.82, lng: -96.1 };
export const connectCurrentMapBounds = createConnector({
    displayName: 'BoundsProvider',
    getProvidedProps(props, searchState, { results }, metadata) {
        const { google, selectedLocation, includeUserLocationInBounds } = props;
        const { selectedPlace, expandSearch, } = searchState.mapBounds || { expandSearch: false };
        let bounds = selectedPlace && !expandSearch && isTooBig(selectedPlace) &&
            selectedPlace.geometry.viewport;
        if (!bounds) {
            const userLocation = (selectedPlace && selectedPlace.geometry.location) ||
                // no current location given, see if Algolia parsed it via our IP
                (results && present(results.aroundLatLng) && parsePosition(results.aroundLatLng));
            if (selectedLocation) {
                bounds = calculateBounds(google.maps, selectedLocation._geoloc, includeUserLocationInBounds && userLocation || undefined);
            }
            else if (!results || !results.hits || !results.hits.length) {
                bounds = includeUserLocationInBounds && userLocation ?
                    defaultBoundsAround(google.maps, userLocation) :
                    new google.maps.LatLngBounds(centerUS, centerUS);
            }
            else {
                const hitLocations = results.hits.map((h) => h._geoloc);
                if (includeUserLocationInBounds && userLocation) {
                    // The first closest hit should come first and set the initial bounds,
                    // then the location that they've chosen to search from (or current user location)
                    // should expand the bounds as far as possible in that direction.
                    hitLocations.splice(1, 0, userLocation);
                }
                bounds = calculateBounds(google.maps, ...hitLocations);
            }
        }
        if (!bounds) {
            return { bounds: null };
        }
        return {
            bounds: bounds.toJSON(),
        };
    },
});
function calculateBounds(maps, ...hits) {
    const { LatLngBounds, LatLng } = maps;
    const { computeDistanceBetween, interpolate } = maps.geometry.spherical;
    const bounds = new LatLngBounds();
    hits.slice(0, maxMarkersToShowInitially)
        .filter(present)
        .forEach((geoloc) => {
        if (!bounds.isEmpty()) {
            const from = bounds.getCenter();
            const to = geoloc instanceof LatLng ? geoloc : new LatLng(geoloc.lat, geoloc.lng);
            const metersDistant = computeDistanceBetween(from, to);
            if (metersDistant > maxMapBoundMeters) {
                // This geoloc is > 500km away, so we extend the bounds along that direction
                // but not more than 500km away.
                const maxKmAwayAlongPath = interpolate(from, to, (maxMapBoundMeters) / metersDistant);
                bounds.extend(maxKmAwayAlongPath);
                return;
            }
        }
        bounds.extend(geoloc);
    });
    return bounds;
}
const NorthEastInRadians = 45;
const SouthWestInRadians = 225;
function defaultBoundsAround(maps, position) {
    const { LatLngBounds, LatLng } = maps;
    const { computeOffset } = maps.geometry.spherical;
    const origin = position instanceof LatLng ? position : new LatLng(position.lat, position.lng);
    const bounds = new LatLngBounds();
    bounds.extend(origin);
    // Extend the bounds radially around the origin to the default amount
    // 45 degrees = towards NE corner, 225 = towards SW corner
    bounds.extend(computeOffset(origin, defaultBoundsMeters, NorthEastInRadians));
    bounds.extend(computeOffset(origin, defaultBoundsMeters, SouthWestInRadians));
    return bounds;
}
