import * as React from 'react';
import { connectCurrentRefinements, connectStats, } from 'react-instantsearch-core';
import { connectMapRefinement } from '../connectors/connectMapRefinement';
import { Daynames } from '../types';
import { metersToMiles } from '../utils';
class Stats extends React.Component {
    constructor() {
        super(...arguments);
        this.placeText = () => {
            const { currentRefinement } = this.props;
            if (!currentRefinement.selectedPlace) {
                return '';
            }
            if (currentRefinement.placeFacetValue) {
                return this.props.translate(['locationMap', 'place', 'in'], {
                    place: currentRefinement.placeFacetValue,
                });
            }
            else if (currentRefinement.maxSearchRadiusMeters) {
                const km = Math.floor(currentRefinement.maxSearchRadiusMeters / 10000) * 10;
                const miles = Math.floor(metersToMiles(currentRefinement.maxSearchRadiusMeters) / 10) * 10;
                return this.props.translate(['locationMap', 'place', 'aroundWithMax'], {
                    place: currentRefinement.selectedPlace.name,
                    km,
                    miles,
                });
            }
            else if (currentRefinement.expandSearch) {
                return this.props.translate(['locationMap', 'place', 'distantFrom'], {
                    place: currentRefinement.selectedPlace.name,
                });
            }
            else {
                return this.props.translate(['locationMap', 'place', 'around'], {
                    place: currentRefinement.selectedPlace.name,
                });
            }
        };
        this.locationTypeText = () => {
            const refinements = this.props.items;
            const trainingLocation = refinements.find((r) => r.attribute == 'trainingLocation');
            const locationTypeKey = trainingLocation && trainingLocation.currentRefinement ?
                'trainingLocation' :
                'location';
            return this.props.translate(['locationMap', 'locationType', locationTypeKey], { count: this.props.nbHits });
        };
        this.meetingDayText = () => {
            const refinements = this.props.items;
            const nbHits = this.props.nbHits;
            let meetingDayText;
            const meetingDay = refinements.find((r) => r.attribute == 'meetingDay');
            if (!meetingDay || !meetingDay.currentRefinement) {
                return '';
            }
            const meetingDays = meetingDay.currentRefinement
                .sort(byDayOfWeek)
                .map((day) => this.props.translate(['locationMap', 'meetingDay', 'days_of_week', Daynames.indexOf(day)], { defaultValue: day }));
            const meetingDayCount = meetingDay.currentRefinement.length;
            if (meetingDayCount > 2) {
                meetingDayText = meetingDays.slice(0, meetingDayCount - 1)
                    .join(this.props.translate('locationMap.meetingDay.words_connector'))
                    + this.props.translate('locationMap.meetingDay.last_word_connector')
                    + meetingDays[meetingDayCount - 1];
            }
            else if (meetingDayCount == 2) {
                meetingDayText = meetingDays.join(this.props.translate('locationMap.meetingDay.two_words_connector'));
            }
            else {
                meetingDayText = meetingDays[0];
            }
            return this.props.translate('locationMap.meetingDay', { count: nbHits, days: meetingDayText });
        };
    }
    render() {
        const { nbHits, processingTimeMS, } = this.props;
        const text = this.props.translate('locationMap.locations', {
            count: nbHits,
            seconds: processingTimeMS / 1000,
            place: this.placeText(),
            meetingDay: this.meetingDayText(),
            locationType: this.locationTypeText(),
        });
        return (React.createElement("div", { className: "ais-Stats" },
            React.createElement("span", { className: "ais-Stats-text" }, text)));
    }
}
const ConnectedStats = connectMapRefinement(connectCurrentRefinements(connectStats(Stats)));
export default ConnectedStats;
function byDayOfWeek(a, b) {
    return Daynames.indexOf(a) - Daynames.indexOf(b);
}
