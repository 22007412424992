import * as React from 'react';
import { connectRefinementList } from 'react-instantsearch-core';
import { Daynames } from '../types';
class WeekdayRefinement extends React.Component {
    constructor() {
        super(...arguments);
        this.renderItem = (wday) => {
            const { currentRefinement, translate, refine } = this.props;
            const item = this.props.items.find((i) => i.label == wday) || {
                count: 0,
                isRefined: currentRefinement.indexOf(wday) >= 0,
                value: this.toggleValue(wday),
                label: wday,
            };
            const dayIdx = Daynames.indexOf(wday);
            const dayLabel = translate(['locationMap', 'filters', 'days_of_week', dayIdx]);
            const classNames = [
                'ais-RefinementList-item',
                item.isRefined && 'ais-RefinementList-item--selected',
            ].filter(truthy);
            return React.createElement("li", { className: classNames.join(' '), key: wday },
                React.createElement("label", { className: "ais-RefinementList-label" },
                    React.createElement("input", { className: "ais-RefinementList-checkbox", type: "checkbox", checked: item.isRefined, onChange: () => refine(item.value) }),
                    React.createElement("span", { className: "ais-RefinementList-labelText" }, dayLabel || wday),
                    React.createElement("span", { className: "ais-RefinementList-count" }, item.count)));
        };
        this.toggleValue = (wday) => {
            const { currentRefinement } = this.props;
            const index = currentRefinement.indexOf(wday);
            if (index >= 0) {
                const newRefinement = currentRefinement.slice();
                newRefinement.splice(index, 1);
                return newRefinement;
            }
            return currentRefinement.concat(wday);
        };
    }
    render() {
        return (React.createElement("div", { className: "ais-RefinementList" },
            React.createElement("ul", { className: "ais-RefinementList-list" }, Daynames.map((wday) => {
                return this.renderItem(wday);
            }))));
    }
}
export default connectRefinementList(WeekdayRefinement);
function truthy(x) {
    return !!x;
}
