import * as React from 'react';
import { connectMapRefinement } from '../connectors/connectMapRefinement';
class AutocompleteInput extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.inputRef = React.createRef();
        this.placesChanged = this.placesChanged.bind(this);
    }
    componentDidMount() {
        const current = this.inputRef.current;
        this.autocomplete = new this.props.google.maps.places.Autocomplete(current);
        // prevent the google maps search box from responding to the "Enter" keypress
        current?.addEventListener('keydown', (evt) => {
            if (evt.key == 'Enter') {
                evt.stopImmediatePropagation();
            }
        }, true);
        this.autocomplete.addListener('place_changed', () => this.placesChanged());
    }
    render() {
        const { 
        // google maps provided
        google, loaded, 
        // map refinement connector provided
        refine, createURL, currentRefinement, 
        // our defined prop
        onPlaceChanged, ...inputProps } = this.props;
        return React.createElement("input", Object.assign({}, inputProps, { ref: this.inputRef }));
    }
    placesChanged() {
        if (!this.autocomplete) {
            return;
        }
        const places = this.autocomplete.getPlace();
        if (!places) {
            return;
        }
        if (!places.geometry) {
            return;
        }
        this.props.refine({
            selectedPlace: places,
            expandSearch: false,
        });
        if (this.props.onPlaceChanged) {
            this.props.onPlaceChanged(places);
        }
    }
}
const ConnectedAutocompleteInput = connectMapRefinement(AutocompleteInput);
export default ConnectedAutocompleteInput;
