import { createConnector } from 'react-instantsearch-core';
import { present, isTooBig, milesToMeters, parsePosition } from '../utils';
const maxSearchRadiusMeters = Math.ceil(milesToMeters(50)); // 50 miles
export const connectMapRefinement = createConnector({
    displayName: 'MapBoundsRefinement',
    getProvidedProps(props, searchState, searchResults) {
        const currentRefinement = Object.assign({}, searchState.mapBounds);
        // Mimic the logic inside getSearchParameters, setting appropriate values on currentRefinement
        if (!hasMapRefinements(searchState)) {
            const results = searchResults && searchResults.results;
            // no current location given, see if Algolia parsed it via our IP
            const userLocation = results && present(results.aroundLatLng) && parsePosition(results.aroundLatLng);
            if (userLocation) {
                currentRefinement.userLocation = userLocation;
            }
            return { currentRefinement };
        }
        const { selectedPlace, expandSearch } = searchState.mapBounds;
        currentRefinement.selectedPlace = selectedPlace;
        if (expandSearch) {
            currentRefinement.userLocation = selectedPlace.geometry.location.toJSON();
            return { currentRefinement };
        }
        else if (isTooBig(selectedPlace)) {
            currentRefinement.placeFacetValue = selectedPlace.name;
        }
        else {
            currentRefinement.userLocation = selectedPlace.geometry.location.toJSON();
            currentRefinement.maxSearchRadiusMeters = maxSearchRadiusMeters;
        }
        return { currentRefinement };
    },
    refine(props, searchState, nextRefinement) {
        return {
            ...searchState,
            mapBounds: {
                ...searchState.mapBounds,
                ...nextRefinement,
            },
        };
    },
    getSearchParameters(searchParameters, props, searchState) {
        if (!hasMapRefinements(searchState)) {
            // search around your current location as determined by Algolia
            return searchParameters
                .setQueryParameter('aroundLatLngViaIP', true)
                .setQueryParameter('aroundRadius', 'all');
        }
        const { selectedPlace, expandSearch } = searchState.mapBounds;
        if (expandSearch) {
            // expand out from current location to all hits
            const loc = selectedPlace.geometry.location;
            searchParameters = searchParameters
                .setQueryParameter('aroundLatLng', `${loc.lat()}, ${loc.lng()}`)
                .setQueryParameter('aroundRadius', 'all');
        }
        else if (isTooBig(selectedPlace)) {
            // search within facet of place name
            searchParameters = searchParameters.addDisjunctiveFacet('places')
                .addDisjunctiveFacetRefinement('places', selectedPlace.name);
        }
        else {
            // search in 50 mile radius from selected place (ex. a personal address)
            const loc = selectedPlace.geometry.location;
            searchParameters = searchParameters
                .setQueryParameter('aroundLatLng', `${loc.lat()}, ${loc.lng()}`)
                .setQueryParameter('aroundRadius', maxSearchRadiusMeters);
        }
        return searchParameters;
    },
});
function hasMapRefinements(searchState) {
    return !!(searchState.mapBounds && searchState.mapBounds.selectedPlace);
}
